import React from 'react';
import Summary from './Tables/Summary';
import Detail from './Tables/Detail';
import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Grid";
import { FormControlLabel, Radio, RadioGroup } from "@mui/material";
import { startOfWeekMon, endOfWeekSun, tomorrow, shiftWeek } from "../../utils/dateFunctions";
import { Locations } from "../../utils/constants/locations";
import DateSelector from "../../shared/DateSelector";
import WarehouseSelector from "../../components/WarehouseSelector";
import PurchaseOrders from './Tables/PurchaseOrders';
import POSummaryPoints from './Stats/POSummaryPoints';
import Transfers from './Tables/Transfers';
import { Propane } from '@mui/icons-material';

const ProcurementDashboard = () => {
    const [startDate, setStartDate] = React.useState(startOfWeekMon(new Date()));
    const [endDate, setEndDate] = React.useState((endOfWeekSun(new Date())));
    const startDateMemo = React.useMemo(() => (startDate),[startDate]);
    const endDateMemo = React.useMemo(() => (tomorrow(endDate)), [endDate]);
    const [location, setLocation] = React.useState(0);
    const [exReload, setExReload] = React.useState(false);
    const [inUse, setInUse] = React.useState(false);
    let timer;
    React.useEffect(() => {
        if (!inUse) {
            reloadFunc();
        } else {
            clearInterval(timer);
        }

        return () => clearInterval(timer);
    }, [inUse]);

    const reloadFunc = () => {
        timer = !timer && setInterval(() => {
            console.log('TIMER')
            setExReload(stat => !stat);
        }, 120000)
    }

    const nextWeekfunc = () => {
        var newDates = shiftWeek(startDate, 1);
        setStartDate(newDates.start);
        setEndDate(newDates.end);
    }
    const lastWeekfunc = () => {
        var newDates = shiftWeek(startDate, -1);
        setStartDate(newDates.start);
        setEndDate(newDates.end);
    }

    return (<div onMouseOver={() => { return !inUse ? setInUse(true) : null; }} onMouseLeave={() => { return inUse ? setInUse(false) : null; }}>
        <Grid container spacing={2} mb={1} >
            <Grid item xs={6} sm={6} md={6} lg={6} xl={3}>
                <DateSelector montosun startDate={startDate} setStartDate={setStartDate} endDate={endDate} setEndDate={setEndDate} />
            </Grid>
            <Grid item xs={6} sm={6} md={6} lg={6}>
                <WarehouseSelector location={location} setLocation={setLocation} />
            </Grid>
        </Grid>
        <Grid container spacing={3.5}>
            {/*<Grid item xs={12}>*/}
            {/*    <Summary oldInfo location={location} startDate={startDateMemo} endDate={(endDateMemo)} externalReload={exReload} />*/}
            {/*</Grid>*/}

            <Grid item xs={12}>
                <PurchaseOrders location={location} showQA oldInfo long totals height="3000px" nextWeek={nextWeekfunc} lastWeek={lastWeekfunc} warehouseFilter={Locations[location] ? Locations[location] : null} startDate={startDateMemo} endDate={(endDateMemo)} externalReload={exReload} shrinkTable reverseSort />
            </Grid>
            <Grid item xs={12}>
                <POSummaryPoints warehouseFilter={Locations[location] ? Locations[location] : null} location={location} startDate={startDateMemo} endDate={(endDateMemo)} externalReload={exReload} />
            </Grid>
            <Grid item xs={12}>
                <Transfers location={location}  startDate={startDateMemo} endDate={(endDateMemo)} externalReload={exReload} shrinkTable />
            </Grid>
        </Grid>
    </div >);

};

    


export default ProcurementDashboard;
