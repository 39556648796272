import React from 'react';
import Summary from './Tables/Summary';
import DailyProcurement from './Tables/ProcurementByDay';
import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Grid";
import { FormControlLabel, Radio, RadioGroup } from "@mui/material";
import { startOfWeekMon, endOfWeekSun, tomorrow, shiftWeek, shiftWeekMonToSun } from "../../utils/dateFunctions";
import DateSelector from "../../shared/DateSelector";
import WarehouseSelector from "../../components/WarehouseSelector";
import PurchaseOrders from './Tables/PurchaseOrders';

const ProcurementDashboard = () => {
    const [startDate, setStartDate] = React.useState((startOfWeekMon(new Date())));
    const [endDate, setEndDate] = React.useState((endOfWeekSun(new Date())));
    const startDateMemo = React.useMemo(() => (startDate));
    const endDateMemo = React.useMemo(() => ((endDate)));
    const [location, setLocation] = React.useState(0);
    const [exReload, setExReload] = React.useState(false);
    const [inUse, setInUse] = React.useState(false);
    let timer;
    React.useEffect(() => {
        if (!inUse) {
            reloadFunc();
        } else {
            clearInterval(timer);
        }

        return () => clearInterval(timer);
    }, [inUse]);

    const reloadFunc = () => {
        timer = !timer && setInterval(() => {
            console.log('TIMER')
            setExReload(stat=>!stat);
        }, 120000)
    }

    const nextWeekfunc = () => {
        var newDates = shiftWeekMonToSun(startDate, 1);
        setStartDate(newDates.start);
        setEndDate(newDates.end);
    }
    const lastWeekfunc = () => {
        var newDates = shiftWeekMonToSun(startDate, -1);
        setStartDate(newDates.start);
        setEndDate(newDates.end);
    }

    return (<div onMouseOver={() => { return !inUse ? setInUse(true) : null; }} onMouseLeave={() => { return inUse ? setInUse(false) : null; }}>
        <Grid container spacing={2} mb={1} >
            <Grid item xs={6} sm={6} md={6} lg={6} xl={3}>
                <DateSelector startDate={startDate} setStartDate={setStartDate} endDate={endDate} setEndDate={setEndDate} montosun/>
            </Grid>
            <Grid item xs={6} sm={6} md={6} lg={6}>
                <WarehouseSelector location={location} setLocation={setLocation} />
            </Grid>
        </Grid>
        <Grid container spacing={3.5}>
            {/*<Grid item xs={12}>*/}
            {/*    <Summary location={location} startDate={startDateMemo} endDate={(endDateMemo)} externalReload={exReload} />*/}
            {/*</Grid>*/}
            <Grid item xs={12}>
                <DailyProcurement nextWeek={nextWeekfunc} lastWeek={lastWeekfunc} location={location} startDate={startDateMemo} endDate={(endDateMemo)} externalReload={exReload} />
            </Grid>
            {/*<Grid item xs={12}>*/}
            {/*    <PurchaseOrders nextWeek={nextWeekfunc} lastWeek={lastWeekfunc} location={location} startDate={startDateMemo} endDate={(endDateMemo)} dashboard dayToggle externalReload={exReload} />*/}
            {/*</Grid>*/}
            {/*<Grid item xs={12}>*/}
            {/*    <Transfers location={location} startDate={startDateMemo} endDate={(endDateMemo)} externalReload={exReload} />*/}
            {/*</Grid>*/}
        </Grid>
    </div >);

};



export default ProcurementDashboard;
